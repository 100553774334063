import FooterComponent from "../components/footerComponent";
import HeaderComponent from "../components/headerComponent";

export default function ContactUs () {
  return (<div>
    <HeaderComponent/>
    <div className="relative w-full min-h-52 bg-useit bg-cover bg-center flex text-gray-200 items-center text-3xl font-bold font-serif">
        <div className="w-full h-full absolute bg-logo-blue opacity-80">
          &nbsp;
        </div>
        <span className="z-10"><div className="ml-28 items-start">İletişim</div></span>
      </div>
    <section className="w-full py-12 md:py-12 lg:py-16 container mx-auto">
    <div className="container grid grid-cols-1 gap-10 px-4 md:px-6 lg:grid-cols-2 lg:gap-20">
      <div className="space-y-6">
        <h2 className="text-3xl font-bold text-logo-blue tracking-tighter sm:text-4xl md:text-5xl">Bize Ulaşın</h2>
        <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed dark:text-gray-400">
         Herhangi bir sorunuz mu var? Formu doldurun ve size en kısa sürede ulaşalım.
        </p>
        <div className="space-y-4">
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="mt-1 h-6 w-6 flex-shrink-0 text-gray-500 dark:text-gray-400"
            >
              <line x1="2" x2="5" y1="12" y2="12"></line>
              <line x1="19" x2="22" y1="12" y2="12"></line>
              <line x1="12" x2="12" y1="2" y2="5"></line>
              <line x1="12" x2="12" y1="19" y2="22"></line>
              <circle cx="12" cy="12" r="7"></circle>
            </svg>
            <div>
              <h4 className="font-medium">Adres</h4>
              <p className="text-gray-500 dark:text-gray-400">Edirne/Merkez</p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="mt-1 h-6 w-6 flex-shrink-0 text-gray-500 dark:text-gray-400"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
            </svg>
            <div>
              <h4 className="font-medium">Phone</h4>
              <p className="text-gray-500 dark:text-gray-400">+(90) xxx xxx xx xx </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="mt-1 h-6 w-6 flex-shrink-0 text-gray-500 dark:text-gray-400"
            >
              <rect width="20" height="16" x="2" y="4" rx="2"></rect>
              <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
            </svg>
            <div>
              <h4 className="font-medium">Email</h4>
              <p className="text-gray-500 dark:text-gray-400">info@garantilievim.com</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <form className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                for="name"
              >
                İsminiz
              </label>
              <input
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="name"
                placeholder="İsminizi giriniz"
              />
            </div>
            <div className="space-y-2">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                for="email"
              >
                E-mail
              </label>
              <input
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="email"
                placeholder="E-mail adresinizi giriniz"
                type="email"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              for="subject"
            >
              Konu
            </label>
            <input
              className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              id="subject"
              placeholder="Konu"
            />
          </div>
          <div className="space-y-2">
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              for="message"
            >
              Mesaj
            </label>
            <textarea
              className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 min-h-[150px]"
              id="message"
              placeholder="Mesajınız.."
            ></textarea>
          </div>
          <button
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-logo-blue text-white hover:bg-logo-blue/90 h-10 px-4 py-2 w-full"
            type="submit"
          >
            Gönder
          </button>
        </form>
      </div>
    </div>
  </section><div className="container mx-auto">
<FooterComponent/>
  </div></div>
    )
}