import { GiFamilyHouse } from "react-icons/gi";
export default function FooterComponent() {
  return (
    <div>
      <div className="mt-10 pt-10 pb-16 border-y-gray-300 border border-1 border-x-0 flex">
        <div className="max-w-64 flex flex-col lg:mr-44">
          <div className="font-bold text-logo-blue flex items-center text-2xl">
          <div className="w-14">
          <img src="/assets/logo/logo-01.png"></img></div>
          <div className="ml-2 text-black font-bold"> Garantili Evim </div>
          </div>
          <div className="mt-2 text-gray-500 font-semibold">
          Garantili Evim, ev arayanlar ve satıcıları bir araya getiren bir platformdur.
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-3 text-gray-500 font-semibold">
            <div>Hakkında</div>
            <div>Basın</div>
            <div>Yatırımcı İlişkileri</div>
            <div>Blog</div>
          </div>
          <div className="flex flex-col gap-3 text-gray-500 font-semibold">
            <div>Kariyer</div>
            <div>Temsilci Yönlendirmeleri</div>
            <div>İletişim</div>
            <div>Yardım</div>
          </div>
          <div className="flex flex-col gap-3 text-gray-500 font-semibold">
            <div>Cookie Bilgilendirmesi</div>
            <div>KVKK</div>
            <div>Hizmet Bilgilendirmesi</div>
            <div>Gizlilik Politikası</div>
            <div>Hizmet ve Şartlar</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center py-2">
        <div className="flex font-bold text-sm">
          <div className="text-logo-blue">© 2024 Garantili Evim</div>
          <div className="text-gray-700 font-semibold ml-2">
            Tüm hakları saklıdır.
          </div>
        </div>
        <div className="w-24">  <img src="/assets/logo/brave-logo.svg"></img> </div>
      </div>
    </div>
  );
}
