export default function WhyUs() {
    return (   
        <div className="">
            Son dönemlerde Gayrimenkul sektöründeki
spekülasyonlar, pazarlanan ürünler ile ilgili
yanlış veriler, pazarlayan kişinin pazarladığı
ürün ile ilgili yeterli bilgiye sahip olmaması,
birçok sözleşme akdinin feshine neden
olmaktadır.
Bu da sektörde zaman kaybına, pazarlanan
ürünün sahibinin gelir kaybına ve pazarlayan
kişi veya firmanın itibar kaybına neden
olmaktadır.
Portalın ana amacı sektördeki yukarıda
sayılan tüm sorunların bertaraf edilmesidir.
 
garantilievim.com portalında satışa
sunulan ekspertiz raporlu ürünlerin
kusursuz olduğu anlamına gelmemektedir.
Bu portalın amacı satışa sunulan ürünlerin
olumlu veya olumsuz tüm yönleri
hakkında bilgilerin doğru bir şekilde alıcıya
iletilmesini sağlamaktır.
        </div>
    )  
}