import FooterComponent from "../components/footerComponent";
import HeaderComponent from "../components/headerComponent";
import OtherHouses from "../components/otherHouses";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function LandingPage() {
  return (
    <div>
      <HeaderComponent />
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 mt-10 container mx-auto ">
        <div className="flex flex-col gap-2 mt-24">
          <div className="text-logo-blue text-md font-semibold pl-1"> Garantili Evim</div>
          <div className="font-bold text-6xl font-sans mb-2">
Eksper Raporlu Garantili Evler</div>
          <div className="text-gray-600 text-xl">
            Hayalinizdeki Eve Kavuşmanıza Son Bir Adım Kaldı.
          </div>
          <Link to="/hakkimizda" ><button className="btn bg-logo-blue w-full max-w-48 text-white rounded-full hover:bg-[#2e5870] mt-5">
            Daha Fazla Bilgi
          </button></Link>
        </div>
        <div>
          <img
            src="/assets/landing/landing-2.png"
            alt="landing-agent-2"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="mt-44 flex flex-col">
        <div className="bg-logo-blue z-0 absolute w-full h-[340px]"> &nbsp;</div>
        <div className="container mx-auto my-16 font-bold text-2xl text-white z-20">
          İlgilenebileceğiniz İlanlar
        </div>
        <div className="grid grid-cols-4 gap-5 gap-y-10 z-20  container mx-auto">
        <OtherHouses
          photo={"/assets/exampleHouses/3.jpg"}
          title="Enez Çataltepe Mahallesinde 3+1 Daire"
          location="Edirne Enez Çataltepe Mah."
          price="3.500.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/1.jpg"}
          title="Şükrüpaşa Mahallesinde 4+2 Dubleks Daire"
          location="Edirne Merkez Şükrüpaşa Mah."
          price="3.700.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/2.jpg"}
          title="Keşan'da 2+1 Daire"
          location="Edirne Keşan Yenimescit Mah."
          price="2.000.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/4.jpg"}
          title=" İpsala Atatürk Mahallesinde Müstakil Ev"
          location="Edirne İpsala Atatürk Mah."
          price="1.350.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/5.webp"}
          title="Özgür Çocuklar Parkı Karşısında 5+1 Daire"
          location="Edirne Merkez Özgür Çocuklar Parkı."
          price="6.100.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/6.jpg"}
          title="2+1 Daire"
          location="Edirne Merkez Kemikçiler Mah."
          price="1.800.000"
        />{" "}
        <OtherHouses
          photo={"/assets/exampleHouses/7.jpg"}
          title="Kocasinan Mahallesinde Eşyalı1+1 Daire"
          location="Edirne Merkez Kocasinan Mah."
          price="3.000.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/8.jpg"}
          title="Enez'de Müstakil 4+1 Yazlık"
          location="Edirne Enez Fatih Vakıf Köyü"
          price="3.500.000"
        />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-5 lg:gap-20 mt-24 w-full rounded-2xl bg-slate-200 p-10 container mx-auto">
        <div className="lg:p-10">
          <img
            src="/assets/landing/landing-agent.png"
            className="rounded-xl shadow-2xl"
            alt="landing-agent"
          />
        </div>
        <div className="flex flex-col gap-10 lg:pl-5 lg:py-10 lg:pr-20">
          <div className="font-bold text-3xl pt-20">
            Her Aşamada Müşteri Memnuniyeti!
          </div>
          <div className="text-gray-600">
            Garantili Evim olarak, ev bulma sürecinizi kolaylaştırmak için uzman
            bir ekibimizle ihtiyaçlarınıza uygun mükemmel bir ev bulmanıza
            yardımcı olmak için buradayız. Bizimle iletişime geçin ve
            hayalinizdeki evi bulma yolculuğunuzu başlatın!
          </div>
          <label className="input rounded-full input-bordered min-w-80 max-h-10 pr-0 flex items-center gap-0">
            <input
              type="text"
              className="grow"
              placeholder="E-mail adresinizi girin ve bültene kayıt olun!"
            />
            <div className="bg-logo-blue text-white rounded-full max-h-10 p-3">
              <div className="cursor-pointer flex items-center max-h-4 gap-1 px-4">
                Kayıt ol
              </div>
            </div>
          </label>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 my-5 lg:my-20 container mx-auto">
        <div className="col-span-1 flex flex-col  mx-5 sm:mx-0 mb-5">
          <div className="mt-48 font-semibold text-3xl max-w-[400px]">
            <div>
              <span className="font-bold">Garantili Evim</span> mobil uygulamasıyla ev aramak artık parmaklarınızın
              ucunda!
            </div>
          </div>
          <div className="grid grid-cols-2 max-w-96 mt-16 gap-2">
            <button className="btn bg-logo-blue text-white rounded-full hover:bg-logo-hover">
              <FaApple size={30} />
              <div className="ml-1 flex flex-col items-start text-xs">
              <div className="text-white">App Store'dan</div>

                <div className="text-gray-300">İndir</div>
              </div>
            </button>
            <button className="btn bg-slate-200 text-logo-blue rounded-full hover:bg-slate-300">
              <FaGooglePlay size={30} />
              <div className="ml-1 flex flex-col items-start text-xs">
           
                <div className="text-logo-blue">Play Store'dan</div>
                <div className="text-logo-blue">İndir</div>
              </div>
            </button>
          </div>
        </div>
        <div className="col-span-1">
          <img
            className="w-full"
            src="/assets/landing/landing-3.png"
            alt="mobile"
          />
        </div>
      </div>

      <div className="container mx-auto">
        <FooterComponent />
      </div>
    </div>
  );
}
