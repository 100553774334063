import React from "react";
import { Routes, Route } from "react-router-dom";
import Corporate from "../layouts/corporate";
import LandingPage from "../pages/landingPage";
import SingleHouse from "../pages/singleHouse";
import Register from "../pages/register";
import Login from "../pages/login";
import AboutUs from "../pages/aboutUs";
import WhyUs from "../pages/whyUs";
import HowWork from "../pages/howWork";
import OurDifference from "../pages/ourDifference";
import RealEstate from "../pages/realEstate";
import ContactUs from "../pages/contactUs";

function AppRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/singlehouse" element={<SingleHouse />} />
        <Route path="/gayrimenkul" element={<RealEstate />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/iletisim" element={<ContactUs />} />
        <Route element={<Corporate />}>
          <Route path="/hakkimizda" element={<AboutUs />} />
          <Route path="/neden-biz" element={<WhyUs />} />
          <Route path="/nasil-calisir" element={<HowWork />} />
          <Route path="/farkimiz" element={<OurDifference/>} />

        </Route>
      </Routes>
    </div>
  );
}

export default AppRouter;
