import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div className="grid sm:grid-cols-2 h-screen ">
      <div className="hidden sm:block">
        <img
          src="/assets/auth.png"
          alt="register"
          className="w-full h-screen "
        />
      </div>
      <div className="flex flex-col items-center justify-center text-center text-logo-blue gap-5 mx-10 px-24">
        <Link to="/">
          <div className="max-w-32">
           <img src="/assets/logo/logo-01.png"></img>
          </div>
        </Link>
        <div className="text-black font-bold text-2xl">Giriş yap</div>
        <div className="text-gray-700 dark:text-gray-300">
          Giriş yapmak için lütfen aşağıdaki bilgileri doldurun.
        </div>
        <div className="flex flex-col text-start w-full gap-2">
          <label className="font-semibold">
            <span className="text-red-500">* </span>E-mail
          </label>
          <input
            type="text"
            placeholder="xxx@mail.com"
            className="input border border-1 border-gray-300"
          />
        </div>
        <div className="flex flex-col text-start w-full gap-2">
          <label className="font-semibold">
            <span className="text-red-500">* </span>Şifrenizi giriniz
          </label>
          <input
            type="password"
            placeholder="password"
            className="input w-full border border-1 border-gray-300"
          />
        </div>
        <button className="btn w-full bg-logo-blue text-white hover:bg-logo-hover">
          Giriş yap
        </button>
        <div className="font-normal text-black">
          {" "}
          Hesabın yok mu?{" "}
          <Link to="/register" className="font-semibold">
            {" "}
            <span className="text-logo-blue cursor-pointer">
              {" "}
              Hesap oluştur
            </span>{" "}
          </Link>{" "}
        </div>
      </div>
    </div>
  );
}
