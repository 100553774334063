export default function HouseFeatures( { count, label }) {
    return (
        <div className="flex">
        <div className="flex gap-1 border border-1 py-2 px-4 rounded-full border-gray-200">
           <div className="text-logo-blue font-bold"> {count}
          </div>
          <div className="font-bold"> {label}</div>
           </div>
           
      </div>
    );
    }