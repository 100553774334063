export default function OurDifference() {
  return (
    <div>
      {" "}
      <ul>
        <li>
          1- Mevcut ilan sitelerinden farklı olarak gayrimenkulün yasal yüz
          ölçümü ve mevcut yüz ölçümü belirtilecektir. Yasal yüz ölçümü ile
          mevcut yüzölçümü arasında fark var ise bu farklar önemli not
          başlığında müşteriler ile paylaşılacaktır.
        </li>
        <li>
          {" "}
          2- Taşınmazın satış aşamasında taraflar arasında sorun çıkmaması için
          mal sahibinin onayı ile KVK kurallarına uygun şekilde Takyidat
          bilgileri müşteri ile paylaşılacaktır. Takyidat başlıkları Kamu Haczi,
          Haciz, İpotek, Hisseli Taşınmaz, Şerh ve diğer şeklinde olacaktır. Bu
          bilgiler detay verilmeden sadece Var veya Yok kutucuklarında
          işaretleme şeklinde görülecektir.
        </li>
        <li>
          3- Gayrimenkulün arsa veya tarla olması durumunda yatırım amaçlı
          bölgenin yakın gelecekte gelişim projeksiyon bilgileri
          paylaşılacaktır. Özellikle tarlalarda kadrastal yola cephesi olup
          olmadığı, sulu tarım olup olmadığı gibi, gayrimenkulün değerine önemli
          etkileri olan bilgilere ulaşabilecektir.
        </li>
        <li>
          4- Müşteriler ekspertiz raporundaki fiyat bilgisini göremeyeceklerdir.
          Bunun nedeni satıcının serbest piyasa fiyatlandırmasına engel
          olmamaktır.
        </li>
        <li>
          5- Portalımız 4 farklı dilde hizmet verecektir. Rusça, Arapça,
          İngilizce ve Türkçe dillerinde olup diğer diller için yapay zeka
          aracılığı ile sesli çeviri sistemi kullanılacaktır.
        </li>
        <li>
          6- Ekspertiz raporlarında eksperlerimiz tarafından deprem
          dayanıklılığına karşı bir şüphe oluştuğunda ilana konulmadan önce mal
          sahibinden ürünün depreme dayanıklı olduğuna dair rapor talep
          edilebilecektir.
        </li>
      </ul>
    </div>
  );
}
