import { CiSearch } from "react-icons/ci";
import { GiFamilyHouse } from "react-icons/gi";
import { Link } from "react-router-dom";

export default function HeaderComponent() {
  return (
    <div>
      <div className="hidden sm:block lg:grid grid-cols-2 py-5 w-full container mx-auto">
        <div className="col-span-1 text-logo-blue flex items-center justify-between">
          <Link to="/" className="w-20">
            <img src="/assets/logo/logo-01.png"></img>
          </Link>
          <div>
            <label className="input rounded-full input-bordered min-w-80 max-h-10 pr-0 flex items-center gap-0">
              <input type="text" className="grow" placeholder="İlan Ara.." />
              <div className="bg-logo-blue text-white rounded-full max-h-10 p-3">
                <CiSearch />
              </div>
            </label>
          </div>
        </div>
        <div className="col-span-1 flex justify-between pl-20">
          <div className="navbar bg-base-100">
            <div className="flex-none font-bold">
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} role="button" className="btn m-1">
                  Kategoriler
                </div>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  <li>
                    <Link to="/gayrimenkul"><a>Gayrimenkul</a></Link>
                  </li>
                  <li>
                    <a>Arsa</a>
                  </li>
                  <li>
                    <a>Araç</a>
                  </li>
                </ul>
              </div>
            <Link to="/hakkimizda"><button className="btn m-1">Kurumsal</button></Link>
              <Link to="/iletisim"><button className="btn m-1">İletişim</button> </Link>
              <a>
                <Link to="/login">
                  <button className="btn ml-8 text-md border border-3 hover:bg-white bg-logo-blue text-white font-bold border-logo-blue  hover:border-white hover:text-logo-blue btn-md rounded-full">
                    Giriş Yap
                  </button>
                </Link>
              </a>
              <a>
                <Link to="/register">
                  <button className="btn ml-3 text-md border border-3 bg-white hover:bg-logo-blue hover:text-white font-bold border-logo-blue text-logo-blue btn-md rounded-full">
                    Kayıt Ol{" "}
                  </button>
                </Link>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between sm:hidden mx-5 mt-3">
        <div className="col-span-1 text-logo-blue flex items-center justify-between">
          <Link to="/">
            <GiFamilyHouse size={50} />
          </Link>
          <div>
            <label className="input rounded-full input-bordered max-h-10 pr-0 flex items-center gap-0">
              <input type="text" className="grow" placeholder="Search" />
              <div className="bg-logo-blue text-white rounded-full max-h-10 p-3">
                <CiSearch />
              </div>
            </label>
          </div>
        </div>
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn m-1">
            Click
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <a>Item 1</a>
            </li>
            <li>
              <a>Item 2</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
