import { Link } from "react-router-dom";

export default function Register() {
  return (
    <div className="grid sm:grid-cols-2 h-screen ">
      <div className="flex flex-col items-center justify-center text-center gap-5  px-24 mx-10 text-logo-blue">
        <Link to="/">
          <div className="max-w-32">
            <img src="/assets/logo/logo-01.png"></img>
          </div>
        </Link>
        <div className="font-bold text-2xl text-black"> Hesap oluştur</div>
        <div className="text-gray-700 dark:text-gray-300">
          Kayıt olmak için lütfen aşağıdaki bilgileri doldurun.
        </div>
        <div className="flex flex-col text-start w-full gap-2">
          <label className="font-semibold">
            <span className="text-red-500">* </span>E-mail
          </label>
          <input
            type="text"
            placeholder="xxx@mail.com"
            className="input border border-1 border-gray-300"
          />
        </div>
        <div className="flex flex-col text-start w-full gap-2">
          <label className="font-semibold">
            <span className="text-red-500">* </span>Şifrenizi Oluşturun
          </label>
          <input
            type="password"
            placeholder="password"
            className="input w-full border border-1 border-gray-300"
          />
        </div>
        <div className="flex flex-col text-start w-full gap-2">
          <label className="font-semibold">
            <span className="text-red-500">* </span>Şifrenizi Tekrar Girin
          </label>
          <input
            type="password"
            placeholder="password"
            className="input w-full border border-1 border-gray-300"
          />
        </div>
        <button className="btn w-full bg-logo-blue text-white hover:bg-logo-hover">
          Hesap Oluştur
        </button>
        <div className=" text-black">
          {" "}
          Hesabın var mı ?{" "}
          <Link to="/login">
            {" "}
            <span className="text-logo-blue cursor-pointer font-semibold"> Giriş yap</span>
          </Link>{" "}
        </div>
      </div>
      <div className="hidden sm:block">
        {" "}
        <img
          src="/assets/auth.png"
          alt="register"
          className="w-full h-screen "
        />{" "}
      </div>
    </div>
  );
}
