export default function HowWork() {
  return (
    <div className="">
      Portalımıza ilan vermek isteyen bireysel ya da kurumsal firmalar
      gayrimenkulleri ile ilgili son 3 ay içerisinde SPK Lisanslı firmalar veya
      eksperler tarafından hazırlanmış değerleme raporu sunmak zorundadırlar.
      Ayıca mal sahibi tarafından temin edilmiş güncel Takbis belgesi de ibraz
      edilmelidir. Eğer ilgili gayrimenkul ile ilgili böyle bir rapor
      bulunmuyorsa şirketimizin diğer çözüm ortağı olan portalımız
      Evinibizesor.com üzerinden tüm Türkiye’deki anlaşmalı değerleme
      şirketlerimiz veya eksperlerimiz üzerinden ücreti karşılığında rapor
      hazırlanacaktır Raporlar hazırlandıktan sonra kişisel verilerin koruma
      kanununa uygun olarak raporlar içerisinde tespiti yapılan hususlar satış
      ilanında paylaşılacaktır. Bunlar başlık olarak taşınmazın yasal yüz
      ölçümü, mevcut yüz ölçümü, taşınmazın projesinin uygun olup olmadığı,
      taşınmaz üzerinde şerh haciz veya benzeri takyidat olup olmadığı ilan
      ekinde paylaşılacaktır. İlandaki gayrimenkullerin pazarlama süresi 1 ayı
      geçtiğinde ilan sahibinden güncel Takbis belgesi talep edilecektir. Bu
      sayede ilanlardaki bilgilerin hata payı asgari düzeye inecek ve kuruluş
      amacındaki gayrimenkul sektöründeki sorunlar çözümlenmiş olacaktır.
    </div>
  );
}
