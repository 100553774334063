import { TiLocation } from "react-icons/ti";
import { LuBookmarkMinus } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function OtherHouses({ title, location, price, photo }) {
  return (
    <div className="flex flex-col p-4 bg-white rounded-xl border border-1 border-gray-100">
      <Link to="/singlehouse">
        <div>
          <img
            className="w-full max-h-44 rounded-2xl"
            src={photo}
            alt="placeholder"
          />
        </div>
        <div className="mt-3 font-semibold min-h-12"> {title} </div>

        <div className="flex mt-1 text-center justify-start text-sm text-gray-400">
          <TiLocation size={20} />
          <div>{location}</div>
        </div>
        <div className="flex items-center justify-between mt-5">
          <div> {price}</div>
          <div className="text-white cursor-pointer">
            <LuBookmarkMinus size={25} fill="#000640" />
          </div>
        </div>
      </Link>
    </div>
  );
}
