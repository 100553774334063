import HeaderComponent from "../components/headerComponent";
import FooterComponent from "../components/footerComponent";
import OtherHouses from "../components/otherHouses";
export default function RealEstate() {
  return (
    <div className="block">
      <div className="container mx-auto">
        <HeaderComponent />
      </div>

      <div className="bg-logo-blue -z-10 relative w-full h-[240px]">&nbsp;</div>
      <div className="container mx-auto -mt-48 mb-10 font-bold text-2xl text-white z-20">
        Gayrimenkul İlanlarımız
      </div>
      <div className="grid grid-cols-4  container mx-auto z-50 gap-4">
        <OtherHouses
          photo={"/assets/exampleHouses/3.jpg"}
          title="Enez Çataltepe Mahallesinde 3+1 Daire"
          location="Edirne Enez Çataltepe Mah."
          price="3.500.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/1.jpg"}
          title="Şükrüpaşa Mahallesinde 4+2 Dubleks Daire"
          location="Edirne Merkez Şükrüpaşa Mah."
          price="3.700.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/2.jpg"}
          title="Keşan'da 2+1 Daire"
          location="Edirne Keşan Yenimescit Mah."
          price="2.000.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/4.jpg"}
          title=" İpsala Atatürk Mahallesinde Müstakil Ev"
          location="Edirne İpsala Atatürk Mah."
          price="1.350.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/5.webp"}
          title="Özgür Çocuklar Parkı Karşısında 5+1 Daire"
          location="Edirne Merkez Özgür Çocuklar Parkı."
          price="6.100.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/6.jpg"}
          title="2+1 Daire"
          location="Edirne Merkez Kemikçiler Mah."
          price="1.800.000"
        />{" "}
        <OtherHouses
          photo={"/assets/exampleHouses/7.jpg"}
          title="Kocasinan Mahallesinde Eşyalı1+1 Daire"
          location="Edirne Merkez Kocasinan Mah."
          price="3.000.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/8.jpg"}
          title="Enez'de Müstakil 4+1 Yazlık"
          location="Edirne Enez Fatih Vakıf Köyü"
          price="3.500.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/3.jpg"}
          title="Enez Çataltepe Mahallesinde 3+1 Daire"
          location="Edirne Enez Çataltepe Mah."
          price="3.500.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/1.jpg"}
          title="Şükrüpaşa Mahallesinde 4+2 Dubleks Daire"
          location="Edirne Merkez Şükrüpaşa Mah."
          price="3.700.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/2.jpg"}
          title="Keşan'da 2+1 Daire"
          location="Edirne Keşan Yenimescit Mah."
          price="2.000.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/4.jpg"}
          title=" İpsala Atatürk Mahallesinde Müstakil Ev"
          location="Edirne İpsala Atatürk Mah."
          price="1.350.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/5.webp"}
          title="Özgür Çocuklar Parkı Karşısında 5+1 Daire"
          location="Edirne Merkez Özgür Çocuklar Parkı."
          price="6.100.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/6.jpg"}
          title="2+1 Daire"
          location="Edirne Merkez Kemikçiler Mah."
          price="1.800.000"
        />{" "}
        <OtherHouses
          photo={"/assets/exampleHouses/7.jpg"}
          title="Kocasinan Mahallesinde Eşyalı1+1 Daire"
          location="Edirne Merkez Kocasinan Mah."
          price="3.000.000"
        />
        <OtherHouses
          photo={"/assets/exampleHouses/8.jpg"}
          title="Enez'de Müstakil 4+1 Yazlık"
          location="Edirne Enez Fatih Vakıf Köyü"
          price="3.500.000"
        />
      </div>
      <div className="join container mx-auto flex justify-center mt-8">
  <button className="join-item btn bg-logo-blue text-white hover:bg-logo-hover btn-active">1</button>
  <button className="join-item btn">2</button>
  <button className="join-item btn">3</button>
  <button className="join-item btn">4</button>
</div>
      <div className="container mx-auto">
        <FooterComponent />
      </div>
    </div>
  );
}
