import FooterComponent from "../components/footerComponent";
import HeaderComponent from "../components/headerComponent";
import HouseFeatures from "../components/houseFeatures";
import OtherHouses from "../components/otherHouses";
import BreadCrumbs from "../components/breadCrumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { GoLaw } from "react-icons/go";
import { FaStreetView } from "react-icons/fa";
import { LuMapPin } from "react-icons/lu";
import { IoIosSchool } from "react-icons/io";
import { MdCommute } from "react-icons/md";
import { FaBasketShopping } from "react-icons/fa6";
import ImageGallery from "react-image-gallery";

export default function SingleHouse() {
  const images = [
    {
      original: "/assets/exampleHouses/1.jpg",
      thumbnail: "/assets/exampleHouses/1.jpg",
    },
    {
      original: "/assets/exampleHouses/2.jpg",
      thumbnail: "/assets/exampleHouses/2.jpg",
    },
    {
      original: "/assets/exampleHouses/3.jpg",
      thumbnail: "/assets/exampleHouses/3.jpg",
    },
    {
      original: "/assets/exampleHouses/4.jpg",
      thumbnail: "/assets/exampleHouses/4.jpg",
    },
    {
      original: "/assets/exampleHouses/5.webp",
      thumbnail: "/assets/exampleHouses/5.webp",
    },
    {
      original: "/assets/exampleHouses/6.jpg",
      thumbnail: "/assets/exampleHouses/6.jpg",
    },
    {
      original: "/assets/exampleHouses/7.jpg",
      thumbnail: "/assets/exampleHouses/7.jpg",
    },
    {
      original: "/assets/exampleHouses/8.jpg",
      thumbnail: "/assets/exampleHouses/8.jpg",
    },
  ];

  return (
    <div className="">
      <HeaderComponent />
      <BreadCrumbs
        currentpage={
          "Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
        }
      />

      <div className=" mt-5 container mx-auto">
        <ImageGallery
          items={images}
          showPlayButton={false}
          showNav={false}
          thumbnailPosition="right"
        />
      </div>

      <div className="flex flex-col mt-10 container mx-auto ">
        <div className="font-bold text-xl">
          Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030
        </div>
        <div className="text-[#000640] font-bold mt-2 "> 5.050.000 tl</div>
        <div className="font-bold my-4 text-xl">Detaylar</div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-3 max-w-[550px]">
          <HouseFeatures count="4" label="Oda" />
          <HouseFeatures count="2" label="Banyo" />
          <HouseFeatures count="2" label="Balkon" />
          <HouseFeatures count="1" label="Mutfak" />
        </div>
      </div>
      <div className="font-bold my-5 text-xl container mx-auto">
        Civar Bilgileri
      </div>
      <div className="justify-between gap-6 grid lg:grid-cols-6 text-logo-blue sm:grid sm:grid-cols-1 container mx-auto">
        <div className="flex flex-col gap-2 col-span-1">
          <div className="text-5xl bg-gray-300 rounded-2xl flex items-center justify-center min-h-28">
            <LuMapPin />{" "}
          </div>
          <div className="font-bold">Harita Görünümü</div>
          <div>Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030</div>
        </div>
        <div className="flex flex-col gap-2 col-span-1">
          <div className="text-5xl bg-gray-300 rounded-2xl flex items-center justify-center min-h-28">
            <FaStreetView />{" "}
          </div>
          <div className="font-bold">Sokak Görünümü</div>
          <div>Muammer Aksoy Caddesi 1A Köşk Apt</div>
        </div>
        <div className="flex flex-col gap-2 col-span-1">
          <div className="text-5xl bg-gray-300 rounded-2xl flex items-center justify-center min-h-28">
            <IoIosSchool />{" "}
          </div>
          <div className="font-bold">Okullar</div>
          <ul>
            <li>1 Anaokulu</li>
            <li>1 Ortaokul</li>
            <li>1 Lise</li>
          </ul>
        </div>
        <div className="flex flex-col gap-2 col-span-1 ">
          <div className="text-5xl bg-gray-300 rounded-2xl flex items-center justify-center min-h-28">
            <GoLaw />{" "}
          </div>
          <div className="font-bold">Nezihlik</div>
          <div>Edirne'nin en az suç işlenen mahallelerinden birisidir.</div>
        </div>
        <div className="flex flex-col gap-2 col-span-1">
          <div className="text-5xl bg-gray-300 rounded-2xl flex items-center justify-center min-h-28">
            <MdCommute />{" "}
          </div>
          <div className="font-bold">Ulaşım</div>
          <div>Evin önünde durak mevcuttur. Otoparkı vardır.</div>
        </div>
        <div className="flex flex-col gap-2 col-span-1">
          <div className="text-5xl bg-gray-300 rounded-2xl flex items-center justify-center min-h-28">
            <FaBasketShopping />{" "}
          </div>
          <div className="font-bold">Alışveriş ve Yemek</div>
          <ul>
            <li>16 Restorant</li>
            <li>1 Cafe</li>
            <li>2 Park</li>
          </ul>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-4 container mx-auto">
        <div className="text-gray-600 lg:col-span-2 sm:col-span-1">
          <div className="text-xl text-black font-bold mt-16 mb-2">
            Açıklama
          </div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          consequat diam fermentum sagittis finibus. Maecenas in sapien enim.
          Quisque eget urna eget elit tempor pulvinar quis id justo. Duis
          ullamcorper convallis turpis, ut efficitur metus pretium sit amet.
          Proin lobortis enim eget nibh aliquet, in placerat enim luctus. Nulla
          neque nibh, luctus a aliquam at, dictum porttitor justo. Nullam
          dignissim viverra ligula, at facilisis ante faucibus ut. Nulla ut
          malesuada lacus. In hac habitasse platea dictumst. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Pellentesque pharetra sem sed sapien hendrerit, ac suscipit libero
          pulvinar. Nullam magna velit, tempor vel urna sed, egestas laoreet
          libero. Nam sit amet metus malesuada, scelerisque odio at, elementum
          lorem. Duis placerat dolor ut lacinia venenatis.
          <span className="underline text-black font-bold ml-1 cursor-pointer">
            Devamını Oku{" "}
          </span>
        </div>
        <div className="col-span-1">
          <div className="text-xl font-bold mt-16 mb-2"> İlan Sahibi</div>
          <div className="lg:grid-cols-2 sm:grid-cols-1 grid gap-8 max-w-96">
            <div className="">
              <img
                className="max-h-40 w-full rounded-md object-cover"
                src="/assets/hakan-inci.jpg"
                alt="agent"
              />
            </div>
            <div className="flex flex-col">
              <div className="font-bold mt-5">Hakan İnci</div>
              <div className="text-gray-600 font-bold">Danışman</div>
              <button className="btn border border-1 max-w-28 btn-sm mt-4 rounded-full bg-logo-blue hover:border hover:border-1 hover:border-logo-blue text-white hover:text-logo-blue">
                İletişim Kur
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col container mx-auto">
        <div className="font-bold text-xl my-10 ">
          Beğenebileceğin Benzer Evler
        </div>
        <Swiper
          spaceBetween={15}
          slidesPerView={5.2}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}

          modules={[Pagination, Navigation]}
          className="mySwiper justify-between w-full"
        >
          <SwiperSlide>
            <OtherHouses
              title="3+1 Daire"
              photo={"/assets/exampleHouses/1.jpg"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="4.000.000 tl"
            />
          </SwiperSlide>
          <SwiperSlide>
            
            <OtherHouses
              title="2+1 Daire"
              photo={"/assets/exampleHouses/2.jpg"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="2.000.000 tl"
            />
          </SwiperSlide>
          <SwiperSlide>
            <OtherHouses
              title="1+1 Daire"
              photo={"/assets/exampleHouses/3.jpg"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="5.000.000 tl"
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            <OtherHouses
              title="3+1 Daire"
              photo={"/assets/exampleHouses/4.jpg"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="3.500.000 tl"
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            <OtherHouses
              title="2+1 Daire"
              photo={"/assets/exampleHouses/5.webp"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="2.000.000 tl"
            />
          </SwiperSlide>
          <SwiperSlide>
            <OtherHouses
              title="3+1 Daire"
              photo={"/assets/exampleHouses/8.jpg"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="2.850.000 tl"
            />
          </SwiperSlide>
          <SwiperSlide>
            <OtherHouses
              title="3+1 Daire"
              photo={"/assets/exampleHouses/6.jpg"}
              location="Edirne Merkez, Fatih Mahallesi Muammer Aksoy Caddesi 22030"
              price="4.000.000 tl"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container mx-auto">
        <FooterComponent />
      </div>
    </div>
  );
}
