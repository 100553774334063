import { Outlet, useLocation, Link } from "react-router-dom";
import React from "react";
import HeaderComponent from "../components/headerComponent";
import FooterComponent from "../components/footerComponent";
import BreadCrumbs from "../components/breadCrumbs";

export default function Corporate() {
  const { pathname } = useLocation();

  let content;
  switch (pathname) {
    case "/hakkimizda":
      content = <div>Hakkımızda</div>;
      break;
    case "/neden-biz":
      content = <div>Neden Biz</div>;
      break;
    case "/nasil-calisir":
      content = <div>Nasıl Çalışır</div>;
      break;
      case "/farkimiz":
        content = <div>Farkımız</div>;
        break;
    default:
      content = <div>Kurumsal</div>;
  }

  return (
    <div>
      <div className="container mx-auto">
        <HeaderComponent />
      </div>
      <div className="relative w-full min-h-52 bg-useit bg-cover bg-center flex text-gray-200 items-center text-3xl font-bold font-serif">
        <div className="w-full h-full absolute bg-logo-blue opacity-80">
          &nbsp;
        </div>
        <span className="z-10"><div className="ml-28 items-start">{content}</div></span>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-3 gap-3 mt-10 mb-32">
          <div className="flex flex-col mr-20 gap-2 w-full rounded-lg">
            <Link to="/hakkimizda">
              <div
                className={`${
                  pathname === "/hakkimizda"
                    ? "bg-logo-blue text-[#F1FBFE] "
                    : "bg-[#F1FBFE] text-logo-blue"
                } cursor-pointer text-lg hover:bg-logo-blue hover:text-slate-100 p-2 `}
              >
                Hakkımızda
              </div>
            </Link>
            <Link to="/neden-biz">
              <div
                className={`${
                  pathname === "/neden-biz"
                    ? "bg-logo-blue text-[#F1FBFE] "
                    : "bg-[#F1FBFE] text-logo-blue"
                } cursor-pointer text-lg hover:bg-logo-blue hover:text-[#F1FBFE] p-2 `}
              >
                Neden Biz
              </div>
            </Link>
            <Link to="/nasil-calisir">
              <div
                className={`${
                  pathname === "/nasil-calisir"
                    ? "bg-logo-blue text-[#F1FBFE] "
                    : "bg-[#F1FBFE] text-logo-blue"
                } cursor-pointer text-lg hover:bg-logo-blue hover:text-[#F1FBFE] p-2`}
              >
                Nasıl Çalışır
              </div>
            </Link>
            <Link to="/farkimiz">
              <div
                className={`${
                  pathname === "/farkimiz"
                    ? "bg-logo-blue text-[#F1FBFE] "
                    : "bg-[#F1FBFE] text-logo-blue"
                } cursor-pointer text-lg hover:bg-logo-blue hover:text-[#F1FBFE] p-2`}
              >
                Farkımız
              </div>
            </Link>
          </div>
          <div className="col-span-2 text-justify">
            <Outlet />
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  );
}
