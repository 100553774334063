export default function AboutUs() {
  return (
    <div>
      <div>
        Hoş Geldiniz! GarantiliEvim, güvenilir gayrimenkul alım satım işlemleri
        için özel olarak tasarlanmış bir platformdur. Misyonumuz,
        müşterilerimize eksiksiz ve güvenilir bilgilerle donatılmış, ekspertiz
        raporlu gayrimenkulleri sunarak, mülk alım satım sürecini daha kolay ve
        güvenli hale getirmektir. Bizimle, gayrimenkul alıcıları ve satıcıları
        arasında güvenilir bir köprü kurabilirsiniz. Her bir ilanımız, detaylı
        bir ekspertiz raporu ile desteklenir ve mülkler ile ilgili yanlış veya
        yanıltıcı bilgilere yer verilmez. Bu sayede, alıcılarımız satın
        aldıkları gayrimenkullerin gerçek değerini tam olarak anlarlar ve
        beklenmedik sürprizlerle karşılaşmazlar. GarantiliEvim, gayrimenkul
        piyasasında güvenilirlik ve şeffaflık standartlarını yükseltmeyi
        hedefler. Sitemizde yer alan her ilan, titizlikle incelenir ve doğru
        bilgilerle sunulur. Böylece, mülk alım satımında yaşanan belirsizlikler
        en aza indirilir ve her iki taraf da adil bir şekilde işlem yapabilir.
        Siz de GarantiliEvim ayrıcalıklarından yararlanmak ve güvenilir
        gayrimenkul alım satımı deneyimlemek için hemen sitemizi ziyaret edin.
        Güven ve kaliteye adanmış bu platformda, hayalinizdeki mülke güvenle
        adım atabilirsiniz. Teşekkür ederiz, GarantiliEvim ailesi olarak sizleri
        aramızda görmekten mutluluk duyarız.
      </div>
    </div>
  );
}
